import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, SectionTitle, ContactBlock, CtaButton, ImageLeft, ContactForm, PhoneLink, ContentP } from "../styles/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/small-banner"
import Carousel from "../components/carousel"

import image from "../images/about-image.jpg"

const About = () => {

  const data = useStaticQuery(graphql`
  query About {
    image: file(relativePath: {eq: "sample-image.jpg"}) {  
      id
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="About" />
      <SmallBanner image={image} position="center bottom">
        <Container style={{maxWidth:'1440px'}}>
          <h1>About Us</h1>
        </Container>
      </SmallBanner>
      <Container style={{maxWidth:'1440px'}}>
        <ContactBlock>
          <SectionTitle>A bit about us</SectionTitle>
          <ContentP style={{marginBottom:'50px'}}>
            <p>We have been working across industry for the last 18 years designing and delivering learning solutions both classroom based and more recently virtual. Our experience is rich and varied; training and coaching thousands of delegates from cross industry and seniority from diverse backgrounds and nationalities.</p>
            <p>We believe that human talent and potential is unlimited. Unlocking that, is key to personal and organisational success. Starting with where you want to get to (your vision, mission, purpose) then believing it is possible and achievable (flexing your mindset).</p>
            <p>We provide popular courses off the shelf which can be broken down into modular form and blended to fit organisational needs or build bespoke training solutions.</p>
            <p>Transformational coaching either for organisations or private clients.</p>
            <p>Clients include:</p>
            <ul>
              <li>DEFRA</li>
              <li>Councils</li>
              <li>NHS</li>
              <li>BAWE</li>
              <li>Cottsway Housing</li>
              <li>City College Plymouth</li>
              <li>Kingston Hospital</li>
            </ul>
          </ContentP>

          <Container style={{ width: '90%', maxWidth: 900, padding: '30px 0 60px 0' }}>
            <Carousel />
          </Container>

          <div style={{width:'100%'}}>
            
            <ImageLeft style={{marginTop:'50px'}} mobNoImage>
  
              <div style={{minWidth:'50%'}}>
                <Img fluid={data.image.childImageSharp.fluid} style={{marginRight:'50px'}} />
              </div>
  
              <div style={{minWidth:'50%'}}>
                <ContactForm name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="contact" />
                  <div>
                    <input type="text" name="name" placeholder="Name" />
                    <input type="text" name="email" placeholder="Email" />
                  </div>
                  <div>
                    <textarea placeholder="Message" name="message" />
                  </div>
                  <button type="submit">Send message</button>
                </ContactForm>
              </div>            
  
            </ImageLeft>
          </div>
        </ContactBlock>
        
      </Container>
    </Layout>
  )
} 

export default About
